import React from 'react';
import CSS from 'csstype';

import fullLogo from '../assets/img/full-logo.png';
import fullLogo2x from '../assets/img/full-logo@2x.png';

import './Landing.scss';

const styles = {
  center: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as CSS.Properties,
};

const Landing = (): JSX.Element => {
  return (
    <div style={styles.center} className="container">
      <div className="row">
        <img
          className="logo"
          alt="Yael Chavoya Logo"
          src={fullLogo}
          srcSet={`${fullLogo2x} 2x`}
        />
      </div>
      <div className="row faicon-row">
        <a href="https://github.com/ychavoya" target="_blank" rel="noreferrer">
          <i className="faicon fab fa-github" title="Github" />
        </a>
        <span>|</span>
        <a
          href="https://linkedin.com/in/yael-chavoya"
          target="_blank"
          rel="noreferrer"
        >
          <i className="faicon fab fa-linkedin" title="LinkedIn" />
        </a>
        <span>|</span>
        <a href="mailto:yael@chavoya.me" target="_blank" rel="noreferrer">
          <i className="faicon fas fa-envelope" title="E-mail" />
        </a>
      </div>
    </div>
  );
};

export default Landing;
