import React from 'react';
import { Provider } from 'react-redux';

import Router from './Router';
import store from './store';

import './App.scss';

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
}

export default App;
